export default {
  miniCourse: {
    meta: {
      title: 'Crie um mini-curso 10x mais rápido',
      description:
        'Crie mini-cursos que sua audiência vai amar x10 mais rápido!',
    },
    topic: {
      meta: {
        title: 'Qual a sua especialidade?',
      },
      submit: {
        idle: 'Próximo',
        loading: 'Done!',
        submitting: 'Gerando objetivos de aprendizagem',
      },
      heading:
        'Vamos criar o rascunho do seu mini-curso em apenas três cliques!',
      question:
        'Qual é o tópico que sua audiência vê você como uma autoridade? Uma ou duas frases bastam, mas quanto mais detalhes, melhor!',
      skip: 'Pular, eu não preciso de ajuda',
    },
    learningOutcome: {
      meta: {
        title: 'Ao final desse mini-curso...',
      },
      submit: {
        idle: 'Vamos lá!',
        loading: 'Pronto!',
        submitting: 'Invocando mágico...',
      },
      heading: 'Agora vamos escolher uma promessa de aprendizagem.',
      question:
        'Mini-cursos funcionam melhor quando focados em uma pequena promessa que sua audiência pode alcançar em 30 minutos ou menos. Escolha uma das opções abaixo.',
      back: {
        link: 'Voltar',
      },
      skip: 'Pular, eu não preciso de ajuda',
      showMore: 'Mostrar mais',
    },
  },
};
