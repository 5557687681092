import { localeEn as leapStoriesEn } from 'leap-stories';

export default {
  onboarding: {
    meta: {
      title: 'Make The Leap',
      description: 'Build digital products your audience will love x10 faster!',
    },
    productImageAltText:
      'Image of example digital products sold on The Leap, including "How to make money on tik tok" and "The Creator Cheatsheet"',
    expertise: {
      getStartedTitle: "Let's create your first digital product with AI magic!",
      expertCaption:
        'Quick question: what does your audience see you as an expert on? One sentence will do. Focus on just one area of expertise for now, you can always add more later.',
      cta: 'Next',
      skipButton: "Skip, I don't need help",
      meta: {
        title: 'Make The Leap | Getting started',
      },
      errors: {
        unsafeNiche:
          "Oops! We can't generate topics for that expertise. Please try different wording.",
        missingNiche:
          'Oops! We need an expertise to help you get started. Please let us know before generating learning outcomes.',
        encounteredSecurityCheck:
          'We encountered an issue loading the security check. Please try again or contact support, beta@theleap.co.',
        loadingSecurityCheck:
          "Please wait a moment! We're conducting a necessary security check.",
        rateLimit:
          "Oh snap! We're experiencing high AI demand right now. Please retry later or skip straight to Sign up.",
        tokenLength:
          "Oh snap! We're experiencing high AI demand right now. Please retry later or skip straight to Sign up.",
        unknown:
          "Sorry, we're experiencing a problem with AI requests. Please retry later or skip straight to Sign up.",
      },
      submit: {
        idle: 'Next',
        loading: 'Generating topics',
        submitting: 'Generating topics',
      },
    },
    topics: {
      title: "Now let's pick a topic",
      caption:
        "(Don't worry, you can always come back and select another topic).",
      showMore: 'Show me more topics',
      cta: 'Generate your first product',
      skipButton: "Skip, I don't need help",
      backToExpertise: 'Back to expertise',
      notQuiteRight: '🤔 Not quite right? Enter your own',
      backToTopics: 'Show other options',
      enterOwnTopic: 'Enter your own below. One sentence will do!',
      customTopic: 'Your idea here',
      topicEmptyFill: 'Your topic is empty, please fill it in!',
      topicTooShort: 'Your topic is too short, please elaborate!',
      topicTooLong: 'Your topic is too long, please shorten it!',
      meta: {
        title: 'Make The Leap | Select a topic',
      },
    },
    valueLadder: {
      meta: {
        title: 'Make The Leap | Learn the value ladder',
      },
      next: 'Next',
      mainStepContent: 'Main step content',
      actionSteps: 'Action steps',
      goToStep: 'Go to step',
      illustration: {
        title: 'The 3 steps to success',
        description:
          'Free lead magnet, low-price product, higher-price product',
      },
      step1: {
        heading:
          "While we create your first digital product, we'll let you in on the secret to success...",
        caption: 'Building trust by providing value!',
      },
      step2: {
        heading: 'Create a lead magnet',
        caption:
          'Social algorithms can be fickle: Build a direct relationship with your audience by giving them something valuable in return for their email address.',
      },
      step3: {
        heading: 'Create a low-price product',
        caption:
          'Start your cash flow by emailing your leads an offer for something with more value at a low price.',
      },
      step4: {
        heading: 'Create higher-priced products',
        caption:
          'Really get the 💰 rolling in. Start selling products with even more value at higher prices.',
      },
      step5: {
        goToProduct: {
          ready: 'Personalize',
          building: 'Magic in progress',
        },
        heading: "Let's start with your lead magnet!",
        subheading:
          'Build a direct relationship with your audience by offering this freebie in return for their email address.',
        caption:
          "Here's a first draft we've created just for you. Personalize it with your choice of content, copy, images and brand colors.",
        skipAndBuild: 'Skip and build myself',
        backToTopics: 'Back to topics',
        previewYourFirstDraft: 'Preview your first draft!',
      },
      apiError: {
        rateLimit:
          "Oh snap! We're experiencing high AI demand right now. Sorry, we can't generate your product at the moment. Please retry later or skip straight to Sign up.",
        tokenLength:
          "Oh snap! We're experiencing high AI demand right now. Sorry, we can't complete this product at the moment. You can personalize what's here or retry later.",
        unknown:
          "Sorry, we're experiencing a problem with AI requests. Please retry later or skip straight to Sign up.",
      },
    },
  },
  miniCourse: {
    meta: {
      title: 'Create a mini-course 10x faster',
      description: 'Build mini-courses your audience will love x10 faster!',
    },
    topic: {
      meta: {
        title: "What's your expertise?",
      },
      submit: {
        idle: 'Next',
        loading: 'Done!',
        submitting: 'Generating learning outcomes',
      },
      heading: 'Let’s create your mini-course draft in just three clicks!',
      question:
        'What is the topic that your audience sees you as an authority on? One or two sentences will do, but the more detail, the better!',
      skip: "Skip, I don't need help",
    },
    learningOutcome: {
      meta: {
        title: 'By the end of this mini-course...',
      },
      submit: {
        idle: "Let's do it!",
        loading: 'Done!',
        submitting: 'Invoking wizard...',
      },
      heading: 'Now let’s pick a learning outcome.',
      question:
        'Mini Courses work best when focused on a small promise that your audience can achieve in 30 minutes or less. Choose one from the options below.',
      back: {
        confirmation:
          'Are you sure you want to leave? Your learning outcome suggestions will be lost.',
        link: 'Back',
      },
      skip: "Skip, I don't need help",
      showMore: 'Show more',
    },
    outline: {
      meta: {
        title: 'Say hello to your mini-course',
      },
      unsavedChanges: 'Changes you made may not be saved.',
    },
  },
  dynamicCourseBuilder: {
    root: {
      cta: 'Claim mini-course',
    },
    outlineCompleteModal: {
      title: 'Say hello to your mini-course 🥳',
      paragraph1:
        "We've created a draft to help get you started, and it's only missing one key ingredient: You!",
      paragraph2:
        'Review your mini-course draft and customize it to your liking. Be sure to hit save if you like what you see!',
      cta: 'Got it!',
    },
    overlay: {
      loading: {
        phrase1:
          'Generating your mini-course draft. Hang tight, magic takes a minute 🪄',
        phrase2: "We've made building a course as easy as creating a Reel",
        phrase3:
          "What you're about to see is just a starting point - customize it however you like",
        phrase4: "It's still missing the secret ingredient 😉",
      },
    },
  },
  productBuilder: {
    cta: {
      signup: 'Get Started Free',
    },
    meta: {
      title: 'Create digital products 10x faster',
      description: 'Build digital products your audience will love x10 faster!',
    },
    modal: {
      title: 'Sign up to unlock this feature',
    },
    generateWithAi: '+ Generate with AI',
  },
  errorResponse: {
    layout: {
      title: 'Oops!',
      goBackToHome: 'Go back to home',
      goToSignupPage: 'Go to signup page',
    },
    server: {
      line1: "Sorry to interrupt your flow, we're experiencing some hiccups 🙃",
      line2:
        "We're working on a fix. In the meantime, we'll cool it on giving our computers soda.",
      line3: 'Please try again later, or you can',
      contactUs: 'contact us',
    },
    client: {
      default: {
        line1:
          'Something unexpected happened while processing your request. We apologize for the inconvenience 🙁',
        line2:
          "Please double-check your request and try again or visit the Leap's homepage.",
      },
      400: {
        line1: 'Oops! Your request seems to be missing or has invalid data 🧐',
        line2: 'Please double-check your request and try again.',
      },
      401: {
        line1: "Sorry, you can't access this resource 🔐",
        line2:
          "Please make sure you're logged in with the appropriate credentials.",
      },
      403: {
        line1: "You don't have permission to view this content 🚫",
        line2: "Please double check the URL or visit the Leap's homepage.",
      },
      404: {
        line1: "Looks like we didn't stick the landing on this leap! 🤸‍♀️",
        line2: "We can't find the page you're looking for.",
        line3: "Please double check the URL or visit the Leap's homepage.",
      },
    },
  },
  ...leapStoriesEn,
};
